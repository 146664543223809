import React from 'react';

import { useTranslation } from 'react-i18next';
import { CheckboxArrayField, DropdownField, TextField } from 'src/components/formik-fields/formik-fields';
import {
  LabelWithLock,
  LockedFieldProps,
  LockedFields,
  SwitchesContainer,
} from 'src/components/level-config-edit/level-config-components';
import { comp_ControlType } from 'src/graphql-types/globalUstaTypes';
import { LeagueLevel_competitionLevel_orgLevelConfig_competitionConfigProperties } from 'src/graphql-types/LeagueLevel';

interface Props {
  config: LeagueLevel_competitionLevel_orgLevelConfig_competitionConfigProperties;
  lockFields: LockedFieldProps['lockFields'];
  lockedFields: LockedFields;
}

type ConfigPropertyValue<T extends comp_ControlType> = T extends comp_ControlType.TEXT
  ? string
  : T extends comp_ControlType.DROPDOWN
    ? string
    : T extends comp_ControlType.CHECKBOX_ARRAY
      ? string[]
      : T extends comp_ControlType.TOGGLE
        ? boolean
        : never;

export type ConfigProperty = {
  [T in comp_ControlType]: {
    controlType: T;
    value: ConfigPropertyValue<T>;
  };
}[comp_ControlType];

const LevelConfigControl: React.FC<Props> = ({ config, lockFields, lockedFields }) => {
  const { t } = useTranslation();
  const options = config.valueOptions ?? [];
  const { controlType, key, label } = config;
  const isLocked = (key: string) => Boolean(lockedFields[key]);
  const getLabelWithLock = (label: string, key: string) => {
    return <LabelWithLock lockFields={lockFields} lockedFields={lockedFields} name={key} label={t(label)} />;
  };
  const getOptions = () =>
    options.map((option) => ({
      value: option.key,
      label: t(option.label),
    }));

  switch (controlType) {
    case comp_ControlType.TOGGLE:
      return (
        <SwitchesContainer
          switches={[{ name: key, label: t(label) }]}
          lockedFields={lockedFields}
          lockFields={lockFields}
          key={key}
        />
      );
    case comp_ControlType.TEXT:
      return <TextField disabled={isLocked(key)} name={key} label={getLabelWithLock(label, key)} key={key} />;
    case comp_ControlType.CHECKBOX_ARRAY:
      return (
        <CheckboxArrayField
          name={key}
          disabled={isLocked(key)}
          label={getLabelWithLock(label, key)}
          key={key}
          options={getOptions()}
        />
      );
    case comp_ControlType.DROPDOWN:
      return (
        <DropdownField
          name={key}
          disabled={isLocked(key)}
          label={getLabelWithLock(label, key)}
          key={key}
          options={getOptions()}
        />
      );
    default:
      return null;
  }
};

export default LevelConfigControl;
