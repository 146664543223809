// extracted by mini-css-extract-plugin
export var bodyBase = "level-config-edit-module--body-base--65e13 level-config-edit-module--site-font--553ef";
export var bodyLarge = "level-config-edit-module--body-large--5bbc9 level-config-edit-module--body-base--65e13 level-config-edit-module--site-font--553ef";
export var bodyLargeBold = "level-config-edit-module--body-large-bold--f3617 level-config-edit-module--body-base--65e13 level-config-edit-module--site-font--553ef";
export var bodyRegular = "level-config-edit-module--body-regular--e8b62 level-config-edit-module--body-base--65e13 level-config-edit-module--site-font--553ef";
export var bodyRegularBold = "level-config-edit-module--body-regular-bold--3045e level-config-edit-module--body-base--65e13 level-config-edit-module--site-font--553ef";
export var bodySmall = "level-config-edit-module--body-small--556cf level-config-edit-module--body-base--65e13 level-config-edit-module--site-font--553ef";
export var bodySmallBold = "level-config-edit-module--body-small-bold--712e3 level-config-edit-module--body-base--65e13 level-config-edit-module--site-font--553ef";
export var borderTop = "level-config-edit-module--border-top--a3224";
export var breakWordContainer = "level-config-edit-module--break-word-container--79a59";
export var buttonIconBase = "level-config-edit-module--button-icon-base--c759e";
export var checkboxTableLabel = "level-config-edit-module--checkbox-table-label--9cf0f";
export var clickLink = "level-config-edit-module--click-link--cc623";
export var dropdownBase = "level-config-edit-module--dropdown-base--142ec";
export var dropdownField = "level-config-edit-module--dropdown-field--1b669";
export var dropdownSelectBase = "level-config-edit-module--dropdown-select-base--c7868 level-config-edit-module--text-input--3e6ae";
export var fieldWidth = "level-config-edit-module--field-width--12c91";
export var flexCol = "level-config-edit-module--flex-col--80a75";
export var formErrorMessage = "level-config-edit-module--form-error-message--f7877";
export var h3 = "level-config-edit-module--h3--5e5cf";
export var h4 = "level-config-edit-module--h4--0da85";
export var hoverLink = "level-config-edit-module--hover-link--e5374";
export var hoverRow = "level-config-edit-module--hover-row--a28b5";
export var labelWithLock = "level-config-edit-module--label-with-lock--13d1d";
export var lockIcon = "level-config-edit-module--lock-icon--39ce0 level-config-edit-module--button-icon-base--c759e";
export var lockedByParent = "level-config-edit-module--locked-by-parent--a59d0";
export var membershipContainer = "level-config-edit-module--membership-container--132be level-config-edit-module--flex-col--80a75 level-config-edit-module--primary-border--12c46";
export var membershipHeader = "level-config-edit-module--membership-header--d303b";
export var membershipHeading = "level-config-edit-module--membership-heading--13947";
export var membershipLabel = "level-config-edit-module--membership-label--554d4";
export var moreFiltersBorderClass = "level-config-edit-module--more-filters-border-class--9be19";
export var notifIconFail = "level-config-edit-module--notif-icon-fail--23cb3";
export var notifIconSuccess = "level-config-edit-module--notif-icon-success--4d2d0";
export var padlock = "level-config-edit-module--padlock--ba540";
export var pageBg = "level-config-edit-module--page-bg--d2523";
export var parallelField = "level-config-edit-module--parallel-field--f1e7b";
export var pointer = "level-config-edit-module--pointer--ac13a";
export var primaryBorder = "level-config-edit-module--primary-border--12c46";
export var shadowBoxLight = "level-config-edit-module--shadow-box-light--92349";
export var singlesAndDoubles = "level-config-edit-module--singles-and-doubles--2420b";
export var siteFont = "level-config-edit-module--site-font--553ef";
export var slideDownAndFade = "level-config-edit-module--slideDownAndFade--2774f";
export var slideLeftAndFade = "level-config-edit-module--slideLeftAndFade--cd622";
export var slideRightAndFade = "level-config-edit-module--slideRightAndFade--32801";
export var slideUpAndFade = "level-config-edit-module--slideUpAndFade--dc2c7";
export var statusDecoration = "level-config-edit-module--status-decoration--db91c";
export var switches = "level-config-edit-module--switches--c1439";
export var switchesContainer = "level-config-edit-module--switches-container--b6a72";
export var switchesLocks = "level-config-edit-module--switches-locks--b2d73";
export var textInput = "level-config-edit-module--text-input--3e6ae";
export var textInverted = "level-config-edit-module--text-inverted--aeb86";
export var textMediumDark = "level-config-edit-module--text-medium-dark--0e83e";
export var tooltipFont = "level-config-edit-module--tooltipFont--2bd52";
export var unstyledButton = "level-config-edit-module--unstyled-button--b8178";