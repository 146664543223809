// extracted by mini-css-extract-plugin
export var bodyBase = "league-level-config-edit-module--body-base--f0c4b league-level-config-edit-module--site-font--87bbd";
export var bodyLarge = "league-level-config-edit-module--body-large--6023d league-level-config-edit-module--body-base--f0c4b league-level-config-edit-module--site-font--87bbd";
export var bodyLargeBold = "league-level-config-edit-module--body-large-bold--c8ffe league-level-config-edit-module--body-base--f0c4b league-level-config-edit-module--site-font--87bbd";
export var bodyRegular = "league-level-config-edit-module--body-regular--b5d27 league-level-config-edit-module--body-base--f0c4b league-level-config-edit-module--site-font--87bbd";
export var bodyRegularBold = "league-level-config-edit-module--body-regular-bold--6e4a8 league-level-config-edit-module--body-base--f0c4b league-level-config-edit-module--site-font--87bbd";
export var bodySmall = "league-level-config-edit-module--body-small--117c2 league-level-config-edit-module--body-base--f0c4b league-level-config-edit-module--site-font--87bbd";
export var bodySmallBold = "league-level-config-edit-module--body-small-bold--b3521 league-level-config-edit-module--body-base--f0c4b league-level-config-edit-module--site-font--87bbd";
export var borderTop = "league-level-config-edit-module--border-top--bdc41";
export var breakWordContainer = "league-level-config-edit-module--break-word-container--2cc36";
export var buttonIconBase = "league-level-config-edit-module--button-icon-base--399c7";
export var clickLink = "league-level-config-edit-module--click-link--04cbf";
export var dropdownBase = "league-level-config-edit-module--dropdown-base--8fa59";
export var dropdownField = "league-level-config-edit-module--dropdown-field--39f8c";
export var dropdownSelectBase = "league-level-config-edit-module--dropdown-select-base--f2d92 league-level-config-edit-module--text-input--54d3a";
export var fieldWidth = "league-level-config-edit-module--field-width--aaeae";
export var flexCol = "league-level-config-edit-module--flex-col--a815e";
export var formErrorMessage = "league-level-config-edit-module--form-error-message--dd207";
export var h3 = "league-level-config-edit-module--h3--815b8";
export var h4 = "league-level-config-edit-module--h4--c9866";
export var hoverLink = "league-level-config-edit-module--hover-link--0372b";
export var hoverRow = "league-level-config-edit-module--hover-row--c3b03";
export var labelWithLock = "league-level-config-edit-module--label-with-lock--5e5cc";
export var lockIcon = "league-level-config-edit-module--lock-icon--05893 league-level-config-edit-module--button-icon-base--399c7";
export var lockedByParent = "league-level-config-edit-module--locked-by-parent--65aee";
export var membershipContainer = "league-level-config-edit-module--membership-container--bc8e6 league-level-config-edit-module--flex-col--a815e league-level-config-edit-module--primary-border--a7357";
export var membershipHeader = "league-level-config-edit-module--membership-header--0c380";
export var membershipHeading = "league-level-config-edit-module--membership-heading--64fbb";
export var membershipLabel = "league-level-config-edit-module--membership-label--46c55";
export var moreFiltersBorderClass = "league-level-config-edit-module--more-filters-border-class--9ded8";
export var notifIconFail = "league-level-config-edit-module--notif-icon-fail--c60fe";
export var notifIconSuccess = "league-level-config-edit-module--notif-icon-success--7147a";
export var padlock = "league-level-config-edit-module--padlock--8c362";
export var pageBg = "league-level-config-edit-module--page-bg--f7fb9";
export var parallelField = "league-level-config-edit-module--parallel-field--c7a87";
export var pointer = "league-level-config-edit-module--pointer--12aec";
export var primaryBorder = "league-level-config-edit-module--primary-border--a7357";
export var shadowBoxLight = "league-level-config-edit-module--shadow-box-light--f3668";
export var singlesAndDoubles = "league-level-config-edit-module--singles-and-doubles--eb2d7";
export var siteFont = "league-level-config-edit-module--site-font--87bbd";
export var slideDownAndFade = "league-level-config-edit-module--slideDownAndFade--14999";
export var slideLeftAndFade = "league-level-config-edit-module--slideLeftAndFade--152d4";
export var slideRightAndFade = "league-level-config-edit-module--slideRightAndFade--bad82";
export var slideUpAndFade = "league-level-config-edit-module--slideUpAndFade--3eb27";
export var statusDecoration = "league-level-config-edit-module--status-decoration--6beae";
export var switches = "league-level-config-edit-module--switches--e3bea";
export var switchesContainer = "league-level-config-edit-module--switches-container--40c95";
export var switchesLocks = "league-level-config-edit-module--switches-locks--5fe4c";
export var textInput = "league-level-config-edit-module--text-input--54d3a";
export var textInverted = "league-level-config-edit-module--text-inverted--39b0c";
export var textMediumDark = "league-level-config-edit-module--text-medium-dark--91d86";
export var tooltipFont = "league-level-config-edit-module--tooltipFont--5980c";
export var unstyledButton = "league-level-config-edit-module--unstyled-button--8d49d";