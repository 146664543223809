import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { Form, Formik } from 'formik';
import { navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { meshGatewayClient } from 'src/apollo/client';
import { useOrgId } from 'src/apollo/local-state';
import APIErrorMessage from 'src/components/api-error-message/api-error-message';
import FloatingNotification from 'src/components/floating-notification/floating-notification';
import { GET_LEAGUE_LEVEL, MUTATE_LEAGUE_LEVEL_CONFIG } from 'src/components/leagues/leagues-queries';
import { FieldWidth, LockedFieldProps, LockedFields } from 'src/components/level-config-edit/level-config-components';
import { comp_CompetitionConfigPropertyInput } from 'src/graphql-types/globalUstaTypes';
import {
  LeagueLevel,
  LeagueLevel_competitionLevel_orgLevelConfig_competitionConfigProperties,
  LeagueLevelVariables,
} from 'src/graphql-types/LeagueLevel';
import { UpdateLeagueLevelConfig, UpdateLeagueLevelConfigVariables } from 'src/graphql-types/UpdateLeagueLevelConfig';
import { capitalize } from 'src/utils/helper/rankings';

import { Button, PageHeader, PageMaxWidth, Panel, Spinner } from '@clubspark-react/clubspark-react-tools';

import * as styles from './league-level-config-edit.module.less';
import LevelConfigControl, { ConfigProperty } from './level-config-control';

interface Props {
  levelId: string;
}

type InitialValues = Record<string, ConfigProperty['value']>;

const EditLevelConfig: React.FC<Props> = ({ levelId }) => {
  const { t } = useTranslation();
  const orgId = useOrgId();
  const {
    data,
    loading,
    error,
    refetch: reloadLevelConfig,
  } = useQuery<LeagueLevel, LeagueLevelVariables>(GET_LEAGUE_LEVEL, {
    client: meshGatewayClient,
    variables: { orgId, levelId },
  });

  const [updateLevelConfig, { loading: updating, error: updateError, data: updateData }] = useMutation<
    UpdateLeagueLevelConfig,
    UpdateLeagueLevelConfigVariables
  >(MUTATE_LEAGUE_LEVEL_CONFIG, {
    client: meshGatewayClient,
    onCompleted: () => {
      reloadLevelConfig();
    },
  });

  const [lockedFields, setLockedFields] = useState<LockedFields>({});

  const { orgLevelConfig, levelGroup, name = '' } = data?.competitionLevel ?? {};

  useEffect(() => {
    if (orgLevelConfig) {
      const locked: LockedFields = {};
      orgLevelConfig.competitionConfigProperties.forEach((p) => {
        if (p.lockedByOrg) locked[p.key] = 'you';
        if (p.lockedByAncestor) locked[p.key] = 'parent';
      });
      setLockedFields(locked);
    }
  }, [orgLevelConfig]);

  const lockFields = useCallback((fields: string[], lock = true) => {
    const lockUpdate = fields.reduce((obj, f) => ({ ...obj, [f]: lock ? 'you' : undefined }), {});
    setLockedFields((prev) => ({ ...prev, ...lockUpdate }));
  }, []);

  const title = `${capitalize(levelGroup?.name || '')} ${name}`;

  const [notification, setNotification] = useState<'error' | 'success'>();

  useEffect(() => {
    if (updateError) setNotification('error');
    else if (updateData) setNotification('success');
  }, [updateError, updateData, setNotification]);

  const onSubmit = useCallback(
    (updatedFields: any) => {
      if (!updatedFields) return;
      const fields: comp_CompetitionConfigPropertyInput[] = Object.entries(updatedFields).map(([key, value]) => {
        return {
          key: key,
          value: value,
          lock: lockedFields[key] === 'you',
        };
      });
      updateLevelConfig({
        variables: {
          orgId,
          levelId,
          config: fields,
        },
      });
    },
    [levelId, lockedFields, orgId, updateLevelConfig],
  );

  const initialValues = useMemo(() => {
    if (orgLevelConfig && orgLevelConfig.competitionConfigProperties) {
      return orgLevelConfig.competitionConfigProperties.reduce((obj, p) => {
        return { ...obj, [p.key]: p.value };
      }, {} as InitialValues);
    }
  }, [orgLevelConfig]);

  if (loading) return <Spinner />;
  if (!data || !data?.competitionLevel) return <div>{t('level not found')}</div>;

  return (
    <PageMaxWidth>
      <PageHeader title={title} />
      <Panel>
        {loading && <Spinner />}
        {initialValues && (
          <Formik initialValues={initialValues} onSubmit={onSubmit}>
            <Form>
              {orgLevelConfig && (
                <FormFields
                  configs={orgLevelConfig.competitionConfigProperties}
                  lockedProps={{ lockFields, lockedFields }}
                />
              )}
              <div>
                <Button loading={updating} spacing={{ margins: { lg: 'top' } }} size="md" type="submit">
                  {t('save')}
                </Button>
              </div>
            </Form>
          </Formik>
        )}
        <APIErrorMessage error={error?.message ?? updateError?.message} />
      </Panel>
      <LevelConfigNotification
        onClose={() => setNotification(undefined)}
        notification={notification}
        goBackUrl={`/leagues/level-config?levelGroupId=${(levelGroup?.id || '')?.toLocaleLowerCase()}`}
      />
    </PageMaxWidth>
  );
};

const LevelConfigNotification: React.FC<{
  onClose: () => any;
  goBackUrl: string;
  notification: 'error' | 'success' | undefined;
}> = (props) => {
  const { notification, onClose, goBackUrl } = props;
  const { t } = useTranslation();

  if (!notification) return null;

  return (
    <FloatingNotification
      onClose={onClose}
      message={t(notification === 'error' ? 'level config failed' : 'level config saved')}
      icon={
        notification === 'error'
          ? { name: 'sm-warning', className: styles.notifIconFail }
          : { name: 'md-tick', className: styles.notifIconSuccess }
      }
      actionButton={
        notification === 'success' && (
          <Button linkStyle size={'sm'} onClick={() => navigate(goBackUrl)}>
            {t('back')}
          </Button>
        )
      }
    />
  );
};

const FormFields: React.FC<{
  configs: LeagueLevel_competitionLevel_orgLevelConfig_competitionConfigProperties[];
  lockedProps: LockedFieldProps;
}> = (props) => {
  const { lockedProps, configs } = props;

  return (
    <FieldWidth>
      {configs.map((config) => (
        <LevelConfigControl
          config={config}
          key={config.key}
          lockFields={lockedProps.lockFields}
          lockedFields={lockedProps.lockedFields}
        />
      ))}
    </FieldWidth>
  );
};

export default EditLevelConfig;
