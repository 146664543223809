import React from 'react';

import { RouteComponentProps, Router } from '@reach/router';
import Layout from 'src/components/layout/layout';
import EditLevelConfig from 'src/components/leagues/level-config-edit/league-level-config-edit';
import SEO from 'src/components/seo';
import { usePrivateRoute } from 'src/utils/auth';
import ROUTES from 'src/utils/routes';

const EditLevelConfigPage = () => {
  usePrivateRoute();
  return (
    <Router basepath={ROUTES.LEAGUES_LEVEL_CONFIG}>
      <EditLevelConfigRoute path="/:levelId" />
    </Router>
  );
};

interface EditLevelConfigProps extends RouteComponentProps {
  levelId?: string;
}

export const EditLevelConfigRoute: React.FC<EditLevelConfigProps> = ({ levelId }) => {
  return (
    <Layout>
      <SEO title="Edit Level Configuration" />
      <div>
        <EditLevelConfig levelId={levelId} />
      </div>
    </Layout>
  );
};

export default EditLevelConfigPage;
